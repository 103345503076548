<template>
  <div class="merchant-integral-rule-container">
    <p style="font-weight: bold;text-align: right;margin-top: 20px;">本条款于 2021年11月15日 更新</p>
    <p style="margin-top: 10px;">重庆合必优网络科技有限公司尊重并保护用户隐私，在您使用菜乐购平台（以下简称“本应用”）提供的服务时，本隐私权政策将帮助您了解我们会收集哪些数据、为什么收集这些数据、会利用这些数据做什么以及我们如何保护这些数据。请认真阅读并充分理解本《隐私政策》的全部内容，在阅读过程中如您对本政策内容有任何疑问、意见或建议，请联系我们。</p>
    <br>
    <h1>1.适用范围</h1>
    <p>(a) 在您注册本应用帐号时，您根据本应用要求提供的个人注册信息。您的手机号码初次使用是在注册账号时，当您注册完成后，该手机号码将会成功您的登录账号，以及登录成功后将作为账号和相关订单的联系方式，帮助我们更好的为您服务。</p>
    <p>(b) 在您使用本应用网络服务，或访问本应用平台网页时，本应用自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；</p>
    <p>(c) 本应用通过合法途径从商业伙伴处取得的用户个人数据。</p>
    <h2>您了解并同意，以下信息不适用本隐私权政策：</h2>
    <p>(a) 您在使用本应用平台提供的搜索服务时输入的关键字信息；</p>
    <p>(b) 本应用收集到的您在本应用发布的有关信息数据，包括但不限于参与活动、成交信息及评价详情；</p>
    <p>(c) 违反法律规定或违反本应用规则行为及本应用已对您采取的措施。</p>
    <h1>2.手机终端权限使用及第三方SDK使用情况说明</h1>
    <p>(a)手机终端权限使用</p>
    <p>我们可能使用到的权限如下：</p>
    <p>读取电话状态及MAC信息：当您运行菜乐购Plus时，我们会向您申请获取此权限，目的是正常识别您的本机识别码，方便完成服务推送，例如订单状态消息。</p>
    <p>网络访问：用于访问云端服务器获取应用配置和更新信息。</p>
    <p>访问网络状态：用于检测联网方式，在网络异常状态下避免获取应用配置信息。</p>
    <p>访问WLAN状态：用于获取WIFI连接状态，当应用有更新是是否下载更新包，节省流量。</p>
    <p>位置访问：用于注册获取附近商户以及添加收货地址使用。</p>
    <p>存储和安装其他应用：用于用户下载新版本使用。</p>
    <p>拨打电话：用户可主动发起联系客服的电话。</p>
    <p>相机访问：用于用户便捷扫码获取商品信息以及购买。</p>
    <p>麦克风：用户发起聊天可发送语音信息。</p>
    <p>获取已安装应用列表权限：用于在跳转到第三方应用（例如跳转高德地图导航）时判断是否已安装。</p>
    <p>(b)第三方SDK使用过情况</p>
    <p>我们的应用集成的第三方SDK有：</p>
    <p>极光推送SDK：用于用户获取最新商品和订单消息。可能使用到的终端权限包括：网络访问、访问网络状态、访问WLAN状态、获取手机设备识别码，不开启权限不影响使用。该第三方收集个人信息类型：
    <p>设备信息（IMEI/MAC/Android ID/IDFA/OAID/OpenUDID/GUID/SIM 卡 IMSI 信息等）：用于识别唯一用户，保证消息推送的精准送达； 优化推送通道资源，我们会根据设备上不同APP的活跃情况，整合消息推送的通道资源，为开发者提高消息送达率； 为开发者提供智能标签以及展示业务统计信息的服务； </p>
    <p>网络信息与位置信息（IP地址，WiFi信息，基站信息等相关信息）：优化 SDK 与极光服务器的网络连接请求，保证服务的稳定性和连续性；实现区域推送的功能； </p>
    <p>APP 安装列表信息：我们为向您提供智能推送功能，通过该信息推荐更符合您用户需要的推送内容，减少无用推送信息对您用户的打扰，您可以选择开启或关闭这项服务； </p>
    <p>极光隐私权政策链接：<a href="https://www.jiguang.cn/license/privacy">https://www.jiguang.cn/license/privacy</a></p>
    <p>高德地图SDK：用于添加收货地址自动获取位置信息。可能使用到的终端权限包括：网络访问、访问网络状态、访问WLAN状态、获取位置信息。我们不会向高德地图SDK提供你的任何个人信息，当你注册或者添加收货地址时，我们会向高德地图SDK提供您的位置信息，这些信息我们不会收集存储。</p>
    <p>高德地图隐私政策链接：<a href="https://lbs.amap.com/pages/privacy">https://lbs.amap.com/pages/privacy</a></p>
    <p>微信SDK：用于微信支付、微信登录功能。可能使用到的终端权限包括：网络访问、访问网络状态、访问WLAN状态。我们不会向微信SDK提供你的任何个人信息，当你使用微信支付时，支付动作会交由微信平台，我们不会收集和储存微信支付相关个人信息。当你使用微信登录时，我们将会获取微信SDK返回的唯一信息来进行微信注册登录，我们不会储存微信登录相关个人信息。</p>
    <p>支付宝SDK：用于支付宝支付功能。可能使用到的终端权限包括：网络访问、访问网络状态、访问WLAN状态。我们不会向支付宝SDK提供你的任何个人信息，当你使用支付宝支付时，支付动作会交由支付宝平台，我们不会收集和储存支付宝支付相关个人信息。</p>
    <p>(c)权限设置</p>
    <p>您在选择开启或拒绝开通上述任意权限后可以随时进行开启或关闭操作，具体在您的终端“设置”--“应用程序”，选择对应名称的应用程序，找到相应的权限并选择关闭或开启，不同终端设置方式可以能在差异。</p>
    <p>(d)自启动和关联启动</p>
    <p>为了让您体验APP消息离线提醒功能，更全面体验APP功能，提供更优质的服务，在您同意本隐私政策后，APP在必要时，会开启自启动或关联启动功能。</p>
    <h1>3.信息使用</h1>
    <p>(a)本应用不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的许可，或该第三方和本应用（含本应用关联公司）单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些资料。</p>
    <p>(b)本应用亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何本应用平台用户如从事上述活动，一经发现，本应用有权立即终止与该用户的服务协议。</p>
    <h1>4.信息披露</h1>
    <h2>在如下情况下，本应用将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息：</h2>
    <p>(a)经您事先同意，向第三方披露；</p>
    <p>(b)为提供您所要求的产品和服务，而必须和第三方分享您的个人信息；</p>
    <p>(c)根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；</p>
    <p>(d)如您出现违反中国有关法律、法规或者本应用服务协议或相关规则的情况，需要向第三方披露；</p>
    <p>(e)如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；</p>
    <p>(f)在本应用平台上创建的某一交易中，如交易任何一方履行或部分履行了交易义务并提出信息披露请求的，本应用有权决定向该用户提供其交易对方的联络方式等必要信息，以促成交易的完成或纠纷的解决。</p>
    <p>(g)其它本应用根据法律、法规或者网站政策认为合适的披露。</p>
    <h1>5.信息存储和交换</h1>
    <p>本应用收集的有关您的信息和资料将保存在本应用及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或本应用收集信息和资料所在地的境外并在境外被访问、存储和展示。</p>
    <h1>6.Cookie的使用</h1>
    <p>(a)在您未拒绝接受cookies的情况下，本应用会在您的计算机上设定或取用cookies ，以便您能登录或使用依赖于cookies的本应用平台服务或功能。</p>
    <p>(b)您有权选择接受或拒绝接受cookies。您可以通过修改浏览器设置的方式拒绝接受cookies。但如果您选择拒绝接受cookies，则您可能无法登录或使用依赖于cookies的本应用网络服务或功能。</p>
    <p>(c)通过本应用所设cookies所取得的有关信息，将适用本政策。</p>
    <h1>7.信息安全</h1>
    <p>(a)本应用帐号均有安全保护功能，请妥善保管您的用户名及密码信息。本应用将通过对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在“完善的安全措施”。</p>
    <p>(b)在使用本应用网络服务进行网上交易时，您不可避免的要向交易对方或潜在的交易对。</p>
    <p>(c)若您想要注销您的账号，请在个人中心联系我们的客服人员，我们将在7-15个工作日内解决您的需求。</p>
    <h1>8.本隐私政策的更改</h1>
    <p>(a)如果决定更改隐私政策，我们会在本政策中、本公司网站中以及我们认为适当的位置发布这些更改，以便您了解我们如何收集、使用您的个人信息，哪些人可以访问这些信息，以及在什么情况下我们会透露这些信息。</p>
    <p>(b)本公司保留随时修改本政策的权利，因此请经常查看。如对本政策作出重大更改，本公司会通过网站通知的形式告知。</p>
    <h1>9.联系我们</h1>
    <p>(a)本应用由重庆合必优网络科技有限公司提供，如果你对个人信息保护问题有投诉、建议、疑问，你可以将问题发送 至(kefu@cailgou.com)，我们核查并验证你的用户身份后会及时反馈你的投诉与举报。</p>
    <p>(b)如对本隐私政策内容有任何疑问、意见或建议，你可通过官方网站(www.cailgou.com/)首页底部“合作服务一电话”与我们联系。</p>
    <br>
    <p>为防止向第三方披露自己的个人信息，如联络方式或者邮政地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是本应用用户名及密码发生泄露，请您立即联络本应用客服，以便本应用采取相应措施。</p>
  </div>
</template>

<script>
export default {
  name: 'ClgPlusRule',
  data () {
    return {
      title: '应用隐私政策'
    }
  }
}
</script>
<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

html, body {
  /* height: 100%; */
  /* width: 100%; */
  background: #f8f8f8;
}

.merchant-integral-rule-container {
  padding: 15px;
  box-sizing: border-box;

  p {
    color: #333;
    line-height: 1.8;
  }

  h1 {
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 10px;

    &:first-child {
      margin-top: 0;
    }
  }

  .footer {
    margin-top: 20px;
  }
}
</style>
